import {
  AppSessionCellId,
  CellId,
  CellRunMode,
  assertNever,
  serializeRichText,
} from "@hex/common";
import { useCallback } from "react";

import { useCellContentsGetter } from "../../hex-version-multiplayer/state-hooks/cellContentsStateHooks";
import { useCellGetter } from "../../hex-version-multiplayer/state-hooks/cellStateHooks.js";
import {
  canRunPendingMagicEvent,
  useMagicEventsGetter,
} from "../../hex-version-multiplayer/state-hooks/magicEventStateHooks.js";
import { CellContentsMP } from "../../redux/slices/hexVersionMPSlice";
import { getModel } from "../../state/models/useModel";
import { useAppSessionAOContext } from "../../util/appSessionAOContext";
import { RunCellArgs as OrigRunCellArgs, runCell } from "../../util/runCell";
import { useHexFlag } from "../../util/useHexFlags.js";

interface RunCellArgs {
  cellId: CellId;
  cellRunMode?: CellRunMode;
  appSessionCellId: AppSessionCellId | undefined;
  forceOverwriteCache?: boolean;
  executeTrace?: boolean;
  forLogicView?: boolean;
  maybeSkipSql?: boolean;
}

export interface UseRunCellResult {
  runCell: (args: RunCellArgs) => Promise<void>;
}

export function useRunCell(): UseRunCellResult {
  const { dispatchAO: dispatchAppSessionAO } = useAppSessionAOContext();
  const getCell = useCellGetter({});
  const getMagicEvents = useMagicEventsGetter();
  const magicRunEdits = useHexFlag("magic-run-edits");

  const runCellArgsGetter = useCallback(
    (
      cellContents: CellContentsMP,
      {
        appSessionCellId,
        cellId,
        cellRunMode,
        executeTrace,
        forLogicView,
        forceOverwriteCache,
        maybeSkipSql,
      }: RunCellArgs,
    ): OrigRunCellArgs | undefined => {
      const cell = getCell(cellId);
      const magicEvent = cell.latestMagicEventId
        ? getMagicEvents()[cell.latestMagicEventId]
        : null;

      // if there is a pending magic edit or fix (ie, not an insert), we'd like to run that event instead of the cell's source
      const magicEventToRun =
        magicRunEdits && magicEvent && canRunPendingMagicEvent(magicEvent)
          ? magicEvent
          : null;
      let source: string | undefined = "";

      switch (cellContents.__typename) {
        case "TextCell": {
          return {
            cellId,
            cellRunMode,
            appSessionCellId,
            forceOverwriteCache,
            executeTrace,
            dispatchAppSessionAO,
            forLogicView,
            source: serializeRichText(cellContents.richText),
            maybeSkipSql,
          };
        }
        case "CodeCell":
        case "SqlCell":
        case "MarkdownCell":
          // we want to eagerly use the model source to prevent running
          // with stale source if its available since we locally debounce model source updates
          source =
            getModel(cellContents.cellId)?.getValue() ?? cellContents.source;

          // if there's a pending magic edit, we'd like to run that code, instead of the cell's true source.
          if (magicEventToRun) source = magicEventToRun.result ?? undefined;

          return {
            cellId,
            // when running a Magic edit, we constrain it to cell_only so we don't have to worry about showing downstream cells as being the result of a magic edit
            cellRunMode: magicEventToRun ? CellRunMode.CELL_ONLY : cellRunMode,
            appSessionCellId,
            forceOverwriteCache,
            executeTrace,

            source,
            dispatchAppSessionAO,
            forLogicView,
            maybeSkipSql,
            isPendingMagicDiffRun: !!magicEventToRun,
          };
        // TODO(writeback) - figure this out
        case "WritebackCell":
        case "DisplayTableCell":
        case "MetricCell":
        case "VegaChartCell":
        case "Parameter":
        case "DbtMetricCell":
        case "MapCell":
        case "ChartCell":
        case "PivotCell":
        case "FilterCell":
        case "ComponentImportCell": // TODO(Component import): Figure out cell run mode
        case "BlockCell":
        case "ExploreCell":
          return {
            cellId,
            cellRunMode,
            appSessionCellId,
            forceOverwriteCache,
            executeTrace,
            dispatchAppSessionAO,
            forLogicView,
            maybeSkipSql,
          };
        default:
          assertNever(
            cellContents,
            (cellContents as { __typename: string }).__typename,
          );
      }
    },
    [dispatchAppSessionAO, getCell, getMagicEvents, magicRunEdits],
  );

  const getRunCellArgs = useCellContentsGetter({
    selector: runCellArgsGetter,
  });

  // we try our best to only read data on demand
  // to prevent us from invalidating the returned callback
  const runCellCallback = useCallback(
    async (args: RunCellArgs) => {
      const runCellArgs = getRunCellArgs(args.cellId, args);

      if (runCellArgs == null) {
        return;
      }
      await runCell(runCellArgs);
    },
    [getRunCellArgs],
  );

  return { runCell: runCellCallback };
}
