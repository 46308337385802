import { gql } from "@apollo/client";
import { CellId, DateTimeString, UserId } from "@hex/common";
import { useCallback, useMemo } from "react";
import { shallowEqual } from "react-redux";

import { useSelector, useStore } from "../../redux/hooks.js";
import { Lock, selectLocks, setLocks } from "../../redux/slices/lockSlice.js";
import { selectMe } from "../../redux/slices/meSlice.js";
import { useProjectContext } from "../../util/projectContext.js";
import { CurrentUser } from "../me/useCurrentUser";
import { useProjectVersionEditable } from "../sessionOrProjectEditableHooks.js";

import {
  useAcquireLockMutation,
  usePreemptLockMutation,
} from "./useLock.generated";

gql`
  mutation PreemptLock(
    $hexVersionId: HexVersionId!
    $componentId: String!
    $componentType: LockComponentType!
  ) {
    preemptLock(
      hexVersionId: $hexVersionId
      componentId: $componentId
      componentType: $componentType
    ) {
      id
      componentId
      componentType
      user {
        id
        name
        email
        imageUrl
      }
      expires
      expired
      updatedDate
      deletedDate
    }
  }
`;

gql`
  mutation AcquireLock(
    $hexVersionId: HexVersionId!
    $componentType: LockComponentType!
    $componentId: String!
  ) {
    acquireLock(
      hexVersionId: $hexVersionId
      componentType: $componentType
      componentId: $componentId
    ) {
      id
      componentId
      componentType
      user {
        id
        name
        email
        imageUrl
      }
      expires
      expired
      updatedDate
      deletedDate
    }
  }
`;

export interface UserLock {
  userId: UserId;
  userName?: string;
  imageUrl?: string;
  userEmail: string;
  lastActive: DateTimeString;
}

export type UserLockStatus =
  | "other-user-locked"
  | "unlocked"
  | "current-user-locked";

export type UseGetLockPayload =
  | {
      lockStatus: "unlocked";
      userLock: null;
    }
  | {
      lockStatus: "other-user-locked" | "current-user-locked";
      userLock: UserLock;
    };

export type UseUpdateLockPayload = {
  preemptLock: () => void;
  acquireLock: () => void;
};

export const useLockStatus = (cellId: CellId): UserLockStatus => {
  return useSelector((state) => {
    const lock = selectLocks(state)[cellId];
    const currentUser = selectMe(state);

    if (!isLockValid(lock) || !lock) {
      return "unlocked";
    }

    return lockUserIsMe(currentUser, lock)
      ? "current-user-locked"
      : "other-user-locked";
  });
};

export const useUserLock = (cellId: CellId): UserLock | null => {
  return useSelector((state) => {
    const cellLock = selectLocks(state)[cellId];

    if (!isLockValid(cellLock) || !cellLock) {
      return null;
    }

    return {
      userId: cellLock.user.id,
      userEmail: cellLock.user.email,
      userName: cellLock.user.name ?? "",
      imageUrl: cellLock.user.imageUrl ?? undefined,
      lastActive: cellLock.updatedDate,
    };
  }, shallowEqual);
};

export function useUpdateLock({
  canUnlock,
  cellId,
}: {
  cellId: CellId;
  // This must be passed in instead of looking at `projectVersionEditable` directly
  // because we overwrite `projectVersionEditable` in order to get a readonly UI when a cell is locked
  canUnlock: boolean;
}): UseUpdateLockPayload {
  const { hexVersionId } = useProjectContext();
  const projectVersionEditable = useProjectVersionEditable();

  const store = useStore();
  const dispatch = store.dispatch;

  const getCanAcquireLock = useCallback(() => {
    const state = store.getState();
    const currentUser = selectMe(state);
    const cellLock = selectLocks(state)[cellId];

    // We don't want to try to take other people's locks, but re-upping our own lock
    // is fine and actually needs to happen sometimes (in the case of typing)
    return (
      projectVersionEditable &&
      (!isLockValid(cellLock) || lockUserIsMe(currentUser, cellLock))
    );
  }, [cellId, projectVersionEditable, store]);

  const [preemptLockMutation] = usePreemptLockMutation();

  const preemptLock = useCallback(() => {
    if (canUnlock) {
      void preemptLockMutation({
        variables: {
          hexVersionId,
          componentId: cellId,
          componentType: "CELL",
        },
      }).then(({ data }) => {
        const newLocks = data?.preemptLock.reduce<Record<string, Lock>>(
          (acc, lock) => {
            acc[lock.componentId] = lock;
            return acc;
          },
          {},
        );

        if (newLocks != null) {
          dispatch(setLocks(newLocks));
        }
      });
    }
  }, [canUnlock, cellId, dispatch, hexVersionId, preemptLockMutation]);

  const [acquireLockMutation] = useAcquireLockMutation();
  const acquireLock = useCallback(() => {
    if (getCanAcquireLock()) {
      void acquireLockMutation({
        variables: {
          hexVersionId,
          componentId: cellId,
          componentType: "CELL",
        },
      }).then(({ data }) => {
        const newLocks = data?.acquireLock.reduce<Record<string, Lock>>(
          (acc, lock) => {
            acc[lock.componentId] = lock;
            return acc;
          },
          {},
        );

        if (newLocks != null) {
          dispatch(setLocks(newLocks));
        }
      });
    }
  }, [acquireLockMutation, cellId, dispatch, getCanAcquireLock, hexVersionId]);

  return useMemo(
    () => ({
      preemptLock,
      acquireLock,
    }),
    [acquireLock, preemptLock],
  );
}

function isLockValid(lock: Lock): boolean {
  return (
    lock != null &&
    lock.deletedDate == null &&
    !lock.expired &&
    new Date(lock.expires) > new Date()
  );
}
function lockUserIsMe(
  currentUser: CurrentUser | null | undefined,
  lock: Lock | null | undefined,
): boolean {
  return lock != null && currentUser != null && lock.user.id === currentUser.id;
}
